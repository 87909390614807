<template>
  <el-card class="form-container" shadow="never" style="width: 50%">
    <el-button @click="handleDebugVisible">Debug</el-button>
    <el-dialog title="Debug" :visible.sync="debugVisible" width="50%">
      {{ product }}
    </el-dialog>
    <el-steps :active="active" finish-status="success" align-center>
      <el-step title="Product Info"></el-step>
      <el-step title="SkuStock Info"></el-step>
    </el-steps>
    <product-detail
      v-show="showStatus[0]"
      v-model="product"
      :is-edit="isEdit"
      @nextStep="nextStep"
    >
    </product-detail>
    <sku-stock-detail
      v-show="showStatus[1]"
      v-model="product"
      :is-edit="isEdit"
      @prevStep="prevStep"
      @finishCommit="finishCommit"
    >
    </sku-stock-detail>
  </el-card>
</template>
<script>
import { createProduct, getProductParam, updateProduct } from '@/api/product';
import ProductDetail from './ProductDetail';
import SkuStockDetail from './SkuStockDetail';

export default {
  name: 'ProductForm',
  components: {
    ProductDetail,
    SkuStockDetail,
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    productId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      active: 0,
      product: null,
      debugVisible: false,
      showStatus: [true, false],
    };
  },
  methods: {
    hideAll() {
      for (let i = 0; i < this.showStatus.length; i += 1) {
        this.showStatus[i] = false;
      }
    },
    prevStep(skuStockParams) {
      this.product.skuStockParams = skuStockParams;
      if (this.active > 0 && this.active < this.showStatus.length) {
        this.active -= 1;
        this.hideAll();
        this.showStatus[this.active] = true;
      }
    },
    nextStep(productReturn) {
      this.product = productReturn;
      if (this.active < this.showStatus.length - 1) {
        this.active += 1;
        this.hideAll();
        this.showStatus[this.active] = true;
      }
    },
    finishCommit(skuStockParams) {
      this.product.skuStockParams = skuStockParams;
      this.$confirm('是否要提交该产品', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        let primaryCount = 0;
        for (const i in skuStockParams) {
          if (skuStockParams[i].primary) primaryCount += 1;
        }
        if (primaryCount != 1) {
          this.$message({
            type: 'error',
            message: 'Must have one primary SKU',
            duration: 1000,
          });
          return false;
        }
        if (this.isEdit) {
          updateProduct(this.$route.query.id, this.product).then((response) => {
            this.$message({
              type: 'success',
              message: '提交成功',
              duration: 1000,
            });
            this.$router.back();
          });
        } else {
          createProduct(this.product).then((response) => {
            this.$message({
              type: 'success',
              message: '提交成功',
              duration: 1000,
            });
            setTimeout(() => {
              location.reload();
            }, 1000);
          });
        }
      });
    },
    handleDebugVisible() {
      this.debugVisible = true;
    },
  },
};
</script>
<style>
.form-container {
  width: 800px;
}
</style>
