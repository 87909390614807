<template>
  <div style="margin-top: 50px">
    <el-form
      :disabled.sync="formDisable"
      :model="product"
      :rules="rules"
      ref="productDetailForm"
      label-width="150px"
      style="width: 600px"
      size="small"
    >
      <el-form-item label="Merchant：" prop="merchantId">
        <el-cascader v-model="selectMerchant" :options="merchantOptions">
        </el-cascader>
      </el-form-item>
      <el-form-item label="Merchant Menu：" prop="merchantMenuId">
        <el-cascader
          v-model="selectMerchantMenu"
          :options="merchantMenuOptions"
        >
        </el-cascader>
      </el-form-item>
      <el-form-item
        label="Category："
        prop="categoryIds"
        :rules="[
          { required: true, message: '请选择Category', trigger: 'blur' },
        ]"
      >
        <el-cascader v-model="selectProductCate" :options="productCateOptions">
        </el-cascader>
      </el-form-item>
      <el-form-item
        label="SubCategory："
        prop="categoryIds"
        :rules="[
          { required: true, message: '请选择Sub Category', trigger: 'blur' },
        ]"
      >
        <el-cascader
          v-model="selectProductSubCate"
          :options="productSubCateOptions"
        >
        </el-cascader>
      </el-form-item>
      <el-form-item
        label="Attr Category："
        prop="productAttributeCategoryId"
        :rules="[
          { required: true, message: '请选择Attr Cate', trigger: 'blur' },
        ]"
      >
        <el-cascader v-model="selectAttrCate" :options="attrCateOptions">
        </el-cascader>
      </el-form-item>
      <el-form-item label="Publisher"
        prop="publisher"
        :rules="[
          { required: true, message: '请选择Publisher', trigger: 'blur' },
        ]">
        <el-select v-model="product.publisher" :disabled="productPublisherDisabled">
          <el-option
            v-for="item in adminOptions"
            :value="item.id"
            :key="item.id"
            :label="item.firstName + ' ' + item.lastName"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Name(中文):" prop="localizedName.chs">
        <el-input v-model="product.localizedName.chs"></el-input>
        <el-button @click="handleTranslateName" :disabled="nameTranslated">Translate</el-button>
      </el-form-item>
      <el-form-item label="Name(English):" prop="localizedName.eng">
        <el-input v-model="product.localizedName.eng"></el-input>
      </el-form-item>
      <el-form-item label="Unit(中文):" prop="localizedUnit.chs">
        <el-input v-model="product.localizedUnit.chs"></el-input>
      </el-form-item>
      <el-form-item label="Unit(English):" prop="localizedUnit.eng">
        <el-input v-model="product.localizedUnit.eng"></el-input>
      </el-form-item>
      <el-form-item
        label="Original Price:"
        prop="originalPrice.amount"
        :rules="[
          {
            required: true,
            message: 'Please enter original price',
            trigger: 'blur',
          },
        ]"
      >
        <el-input v-model="product.originalPrice.amount"></el-input>
      </el-form-item>
      <el-form-item
        label="Lowest Price:"
        prop="lowestPrice.amount"
        :rules="[
          {
            required: true,
            message: 'Please enter lowest price',
            trigger: 'blur',
          },
        ]"
      >
        <el-input v-model="product.lowestPrice.amount"></el-input>
      </el-form-item>
      <el-form-item
        label="Reconcile Price:"
        prop="reconcilePrice.amount"
        :rules="[
          {
            required: true,
            message: 'Please enter lowest price',
            trigger: 'blur',
          },
        ]"
      >
        <el-input v-model="product.reconcilePrice.amount"></el-input>
      </el-form-item>
      <el-form-item label="Bulk Threshold:" prop="bulkThreshold">
        <el-input v-model="product.bulkThreshold"></el-input>
      </el-form-item>
      <el-form-item label="Description(中文):" prop="localizedDescription.chs">
        <el-input
          :autoSize="true"
          v-model="product.localizedDescription.chs"
          type="textarea"
          :rows="10"
          placeholder="Please enter description"
          style="width: 100%"
        >
        </el-input>
                <el-button @click="handleTranslateDescription" :disabled="descriptionTranslated">Translate</el-button>
      </el-form-item>
        <el-form-item label="Description(English):" prop="localizedDescription.eng">
        <el-input
          :autoSize="true"
          v-model="product.localizedDescription.eng"
          type="textarea"
          :rows="10"
          placeholder="Please enter description"
          style="width: 100%"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="Product Note:" prop="note">
        <el-input v-model="product.note"></el-input>
      </el-form-item>
      <el-form-item label="Special Priority" prop="specialPriority">
        <el-select
          v-model="product.specialPriority"
          :rules="[
            {
              required: true,
              message: '请选择Special Priority',
              trigger: 'blur',
            },
          ]"
        >
          <el-option v-for="item in [true, false]" :key="item" :value="item">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Online Menu" prop="isOnlineMenu">
        <el-select
          v-model="product.isOnlineMenu"
          :rules="[
            {
              required: true,
              message: '请选择is Online Menu',
              trigger: 'blur',
            },
          ]"
        >
          <el-option v-for="item in [true, false]" :key="item" :value="item">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Tax Rate：">
        <el-select v-model="product.taxRate">
          <el-option
            v-for="item in selectTaxRateList"
            :key="item.value"
            :label="item.name"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Primary Picture">
        <single-upload v-model="primaryPic" auto-complete="off"></single-upload>
      </el-form-item>
      <el-form-item label="Addtional Pictures">
        <multi-upload
          v-model="selectProductPics"
          :isUploading.sync="isUploading"
          :inputList="inputPics"
        ></multi-upload>
      </el-form-item>
      <el-form-item label="Tags">
        <div
          v-for="(item, index) in product.productTagParams"
          v-bind:key="index"
        >
          <el-form-item>
            <p>
              中文：
              <el-input v-model="item.localizedTag.chs"></el-input>
            </p>
            <p>
              English：
              <el-input v-model="item.localizedTag.eng"></el-input>
            </p>
            <el-checkbox v-model="item.primary">Is Primary</el-checkbox>
            <el-button type="mini" @click="removeTag(index)">Delete</el-button>
          </el-form-item>
        </div>
        <el-button type="mini" @click="addTag()">Add</el-button>
      </el-form-item>
      <el-form-item>
        <div v-for="(item, index) in product.skuStockParams" v-bind:key="index">
          <div>SKU {{ index }}</div>
          <el-form-item label="Product sku:" prop="sku">
            <el-input v-model="item.sku" disabled></el-input>
          </el-form-item>
          <el-form-item label="Sku Stock:" prop="stock">
            <el-input v-model="item.stock"></el-input>
            <el-button
              size="mini"
              type="danger"
              v-if="index != 0"
              @click="removeSkuStock(index)"
              >Delete
            </el-button>
          </el-form-item>
        </div>
        <el-button type="mini" @click="addSkuStock()">Add</el-button>
      </el-form-item>
      <el-form-item style="text-align: center">
        <el-button
          type="primary"
          size="medium"
          @click="handleNext('productDetailForm', value)"
          >Next Step: Enter SkuStock Details</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { createProduct, getProductParam, updateProduct } from '@/api/product';
import { fetchList as fetchAdminList } from '@/api/admin';
import { fetchList as fetchMerchantList } from '@/api/merchant';
import { fetchList as fetchAttrCateList } from '@/api/productAttrCate';
import {
  fetchList as fetchProductCateList,
  fetchListByParentId as fetchProductCateListByParentId,
} from '@/api/productCate';
import { fetchListByMerchant as fetchMerchantMenuList } from '@/api/merchantMenu';
import { generateSku } from '@/api/skuStock';
import { translateToEnglish } from '@/api/translate';
import MultiUpload from '@/components/Upload/multiUpload';
import SingleUpload from '@/components/Upload/singleUpload';

const defaultProduct = {
  merchantId: null,
  merchantMenuId: null,
  productAttributeCategoryId: null,
  categoryIds: [null, null],
  publisher: null,
  originalPrice: { currency: 'CAD', amount: null },
  lowestPrice: { currency: 'CAD', amount: null },
  reconcilePrice: { currency: 'CAD', amount: null },
  bulkThreshold: null,
  localizedName: {
    chs: '',
    eng: '',
  },
  localizedUnit: {
    chs: '',
    eng: '',
  },
  localizedDescription: {
    chs: '',
    eng: '',
  },
  sortOrder: 0,
  note: '',
  isOnlineMenu: false,
  skuStockParams: [
    {
      sku: '',
      price: { currency: 'CAD', amount: null },
      stock: 99,
      imageUrl: '',
      primary: false,
      permutationsChs: [],
      permutationsEng: [],
    },
  ],
  productImages: [],
  productTagParams: [],
  taxRate: 0,
  specialPriority: null,
  productType: 'PHYSICAL_PRODUCT',
};
export default {
  name: 'ProductDetail',
  components: { MultiUpload, SingleUpload },
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      product: { ...defaultProduct },
      selectMerchant: null,
      merchantOptions: [],
      selectMerchantMenu: null,
      merchantMenuOptions: [],
      selectProductCate: null,
      productCateOptions: [],
      selectProductSubCate: null,
      productSubCateOptions: [],
      selectAttrCate: null,
      attrCateOptions: [],
      adminOptions: [],
      selectProductPics: [],
      productImages: [],
      isEditLoading: null,
      isUploading: false,
      formDisable: false,
      partialSku: null,
      inputPics: [],
      primaryPic: null,
      descriptionTranslated: false,
      nameTranslated: false,
      productPublisherDisabled: false,
      rules: {
        name: [
          { required: true, message: '请输入商品名称', trigger: 'blur' },
          {
            min: 2,
            max: 140,
            message: '长度在 2 到 140 个字符',
            trigger: 'blur',
          },
        ],
        merchantId: [
          { required: true, message: '请选择Merchant', trigger: 'blur' },
        ],
      },
      selectTaxRateList: [
        { name: 'Tax Included (0%)', value: 0 },
        { name: 'ON HST (13%)', value: 0.13 },
      ],
    };
  },
  created() {
    fetchAdminList().then((response) => {
      this.adminOptions = response.data.data;
      console.log(this.adminOptions);
    });
    if (this.isEdit) {
      this.isEditLoading = true;
      getProductParam(this.$route.query.id).then((response) => {
        this.product = response.data;
        this.selectProductPics = [];
        this.inputPics = [];
        if (response.data.productImages.length > 0) this.primaryPic = response.data.productImages[0].url;
        for (let i = 1; i < response.data.productImages.length; i += 1) {
          this.inputPics.push({
            url: response.data.productImages[i].url,
          });
          this.selectProductPics.push(
            response.data.productImages[i].url,
          );
        }
        if (this.$store.state.permission.role != 'admin' && this.product.publisher) {
          this.productPublisherDisabled = true;
        }
        this.getMerchantList();
        this.getAttrCateList();
        this.getProductCateList();
      });
    } else {
      this.getMerchantList();
      this.getAttrCateList();
      this.getProductCateList();
    }
  },
  watch: {
    selectMerchant(newValue) {
      this.product.merchantId = newValue[0].id;
      this.getMerchantMenuList(newValue[0].id);
      this.generateSku();
    },
    selectMerchantMenu(newValue) {
      this.product.merchantMenuId = newValue[0];
    },
    selectAttrCate(newValue) {
      this.product.productAttributeCategoryId = newValue[0];
    },
    selectProductCate(newValue, oldValue) {
      const parentId = newValue[0].id;
      if (parentId != null) {
        this.getProductSubCateList(parentId);
      }
      if (this.isEdit) {
        if (oldValue == null) return;
        for (let i = 0; i < this.product.categoryIds.length; i += 1) {
          if (this.product.categoryIds[i] == oldValue[0].id) {
            this.product.categoryIds[i] = newValue[0].id;
            break;
          }
        }
      } else this.product.categoryIds[0] = newValue[0].id;
      this.generateSku();
    },
    selectProductSubCate(newValue, oldValue) {
      if (this.isEdit) {
        if (oldValue == null) return;
        for (let i = 0; i < this.product.categoryIds.length; i += 1) {
          if (this.product.categoryIds[i] == oldValue[0].id) {
            this.product.categoryIds[i] = newValue[0].id;
            break;
          }
        }
      } else this.product.categoryIds[1] = newValue[0].id;
      this.generateSku();
    },
    selectProductPics(newValue) {
      this.productImages = [];
      newValue.forEach((url) => {
        if (url.startsWith('https://')) {
          this.productImages.push({ url });
        } else {
          this.$message({
            message: 'DEBUG: URL Invalid',
            type: 'error',
            duration: 3000,
          });
        }
      });
    },
  },
  methods: {
    getMerchantList() {
      fetchMerchantList().then((response) => {
        const list = response.data.data;
        this.merchantOptions = [];
        for (let i = 0; i < list.length; i += 1) {
          this.merchantOptions.push({
            label: list[i].name,
            value: { id: list[i].id, skuSection: list[i].skuSection },
          });
          if (
            this.product.merchantId != null
            && this.product.merchantId == this.merchantOptions[i].value.id
          ) {
            this.selectMerchant = [this.merchantOptions[i].value];
          }
        }
      });
    },
    getMerchantMenuList(id) {
      fetchMerchantMenuList(id).then((response) => {
        const list = response.data;
        this.merchantMenuOptions = [];
        for (let i = 0; i < list.length; i += 1) {
          this.merchantMenuOptions.push({
            label: list[i].name,
            value: list[i].id,
          });
          if (
            this.product.merchantMenuId != null
            && this.product.merchantMenuId == this.merchantMenuOptions[i].value
          ) {
            this.selectMerchantMenu = [this.merchantMenuOptions[i].value];
          }
        }
      });
    },
    getAttrCateList() {
      fetchAttrCateList().then((response) => {
        const list = response.data.data;
        this.attrCateOptions = [];
        for (let i = 0; i < list.length; i += 1) {
          this.attrCateOptions.push({ label: list[i].name, value: list[i].id });
          if (
            this.product.productAttributeCategoryId != null
            && this.attrCateOptions[i].value
              == this.product.productAttributeCategoryId
          ) {
            this.selectAttrCate = [this.attrCateOptions[i].value];
          }
        }
      });
    },
    getProductCateList() {
      fetchProductCateList().then((response) => {
        const list = response.data.data;
        this.productCateOptions = [];
        for (let i = 0; i < list.length; i += 1) {
          if (list[i].categoryType != 'PHYSICAL') continue;
          this.productCateOptions.push({
            label: list[i].name,
            value: { id: list[i].id, skuSection: list[i].skuSection },
          });
          if (
            this.product.categoryIds[0]
              == this.productCateOptions[this.productCateOptions.length - 1].value
                .id
            || this.product.categoryIds[1]
              == this.productCateOptions[this.productCateOptions.length - 1].value
                .id
          ) {
            this.selectProductCate = [
              this.productCateOptions[this.productCateOptions.length - 1].value,
            ];
          }
        }
      });
    },
    getProductSubCateList(parentId) {
      fetchProductCateListByParentId(parentId).then((response) => {
        const list = response.data.data;
        this.productSubCateOptions = [];
        for (let i = 0; i < list.length; i += 1) {
          if (list[i].categoryType != 'PHYSICAL') continue;
          this.productSubCateOptions.push({
            label: list[i].name,
            value: { id: list[i].id, skuSection: list[i].skuSection },
          });
          if (
            this.product.categoryIds[0]
              == this.productSubCateOptions[this.productSubCateOptions.length - 1]
                .value.id
            || this.product.categoryIds[1]
              == this.productSubCateOptions[this.productSubCateOptions.length - 1]
                .value.id
          ) {
            this.selectProductSubCate = [
              this.productSubCateOptions[this.productSubCateOptions.length - 1]
                .value,
            ];
          }
        }
      });
    },
    generateSku() {
      if (this.isEdit && this.isEditLoading) return;
      const { skuStockParams } = this.product;
      if (
        this.selectMerchant == null
        || this.selectProductCate == null
        || this.selectProductSubCate == null
      ) {
        for (let i = 0; i < skuStockParams.length; i += 1) skuStockParams[i].sku = '';
        return;
      }
      const skuStart = `${this.selectMerchant[0].skuSection}-${this.selectProductCate[0].skuSection}-${this.selectProductSubCate[0].skuSection}-`;
      generateSku({ partialSku: skuStart }).then((response) => {
        this.partialSku = response.data;
        this.setSku();
      });
    },
    setSku() {
      let partialSku = this.partialSku;
      if (partialSku == null) {
        if (this.product.skuStockParams[0].sku.length != 16) return;
        partialSku = this.product.skuStockParams[0].sku.substring(0, 14);
      }
      for (let i = 0; i < this.product.skuStockParams.length; i += 1) {
        const str_i = i.toString();
        const lastSection = `00${str_i}`.substring(str_i.length);
        this.product.skuStockParams[i].sku = partialSku + lastSection;
      }
    },
    addSkuStock() {
      this.product.skuStockParams.push(
        JSON.parse(
          JSON.stringify(
            this.product.skuStockParams[this.product.skuStockParams.length - 1],
          ),
        ),
      );
      this.product.skuStockParams[this.product.skuStockParams.length - 1].id = null;
      this.setSku();
    },
    removeSkuStock(index) {
      this.product.skuStockParams.splice(index, 1);
      this.setSku();
    },
    addTag() {
      this.product.productTagParams.push({ localizedTag: { chs: '', eng: '' }, primary: false });
    },
    removeTag(index) {
      this.product.productTagParams.splice(index, 1);
    },
    handleNext(formName) {
      for (let i = 0; i < this.product.skuStockParams.length; i += 1) {
        this.product.skuStockParams[i].price.amount = this.product.originalPrice.amount;
      }
      if (this.primaryPic == null || !this.primaryPic.startsWith('https://')) {
        this.$message({
          message: 'Please upload primary picture',
          type: 'error',
          duration: 2000,
        });
        return false;
      }
      if (this.isUploading) {
        this.$message({
          message: 'Please wait until upload complete',
          type: 'error',
          duration: 1000,
        });
        return false;
      }

      this.product.productImages = [{ url: this.primaryPic }];
      this.productImages.forEach((item) => this.product.productImages.push(item));
      this.product.productImagesEn = this.product.productImages;

      for (let i = 0; i < this.product.skuStockParams.length; i += 1) {
        const match = this.product.productImages.find(
          (imageAsset) => imageAsset.url == this.product.skuStockParams[i].url,
        );
        if (match == undefined) this.product.skuStockParams[i].imageUrl = '';
      }

      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.formDisable = true;
          setTimeout(() => {
            this.formDisable = false;
            this.$emit('nextStep', this.product);
          }, 1000);
        } else {
          this.$message({
            message: 'Form Invalid',
            type: 'error',
            duration: 1000,
          });
          return false;
        }
      });
    },
    handleTranslateDescription() {
      translateToEnglish(this.product.localizedDescription.chs).then((response) => {
        console.log(response);
        this.product.localizedDescription.eng = response.data.choices[0].text.trim();
        this.descriptionTranslated = true;
      });
    },
    handleTranslateName() {
      translateToEnglish(this.product.localizedName.chs).then((response) => {
        this.product.localizedName.eng = response.data.choices[0].text.trim();
        this.nameTranslated = ture;
      });
    },
  },
};
</script>

<style scoped>
</style>
